<script>
    import NumberInput from '$saga/components/NumberInput.svelte';

    export let ageRules,
        formValues,
        childrenAges,
        maxRuleValue,
        maxAgeAllowed,
        minAgeAllowed;
</script>

<div
    class="items-center justify-between {minAgeAllowed >= 18 &&
    (!maxAgeAllowed || maxAgeAllowed > 18)
        ? 'hidden'
        : 'flex'}"
>
    <label for="nbChildren" class="flex flex-col text-content-neutral">
        <span class="font-semibold">Enfants</span>
        <span class="text-sm text-content-quiet">
            {#if !minAgeAllowed && (!maxAgeAllowed || maxAgeAllowed >= 18)}
                0 à 17 ans
            {/if}
            {#if ageRules.min.isUnder18Allowed && (!maxAgeAllowed || maxAgeAllowed >= 18)}
                à partir de {minAgeAllowed} ans
            {/if}
            {#if !minAgeAllowed && ageRules.max.isUnder18Allowed}
                jusqu'à {maxAgeAllowed} ans
            {/if}
            {#if ageRules.min.isUnder18Allowed && ageRules.max.isUnder18Allowed}
                {minAgeAllowed} à {maxAgeAllowed} ans
            {/if}
        </span>
    </label>
    <NumberInput
        bind:value={formValues.children}
        max={maxRuleValue}
        id="nbChildren"
        name="nbChildren"
        minusBtnClasses={formValues.children === 0 && 'opacity-[0.38]'}
        minusBtnProps={{ disabled: formValues.children === 0 }}
        onDecrement={() => {
            if (formValues.children > 0) {
                formValues.children = formValues.children - 1;
                formValues.childrenAges.pop();
            }
        }}
        inputClasses={'focus:!border-blue-brand'}
        plusBtnClasses={formValues.children === maxRuleValue &&
            'opacity-[0.38]'}
        plusBtnProps={{ disabled: formValues.children === maxRuleValue }}
        onIncrement={() => {
            if (maxRuleValue == null || maxRuleValue > formValues.children) {
                formValues.children = formValues.children + 1;
                formValues.childrenAges.push('');
            }
        }}
        inputProps={{
            oninput: (event) => {
                formValues.children = event.target.value;
                const amount = Number(event.target.value);
                if (maxRuleValue == null || amount <= maxRuleValue) {
                    formValues.childrenAges = Array.from(Array(amount));
                }
            },
        }}
    />
</div>

{#if formValues.children > 0}
    <div>
        <p class="mb-xs text-content-quiet">
            Afin de vous proposer le tarif adapté, nous avons besoin de
            connaître l'âge qu'auront les enfants à la fin du séjour.
        </p>

        <!--  eslint-disable-next-line @typescript-eslint/no-unused-vars }-->
        {#each formValues.childrenAges as _, index}
            <div class="mb-xl">
                <select
                    name="childrenAge[]"
                    class="min-h-xl w-full px-m py-xs"
                    required
                >
                    <option class="pax-selector__placeholder" value=""
                        >Âge enfant {index + 1}
                    </option>
                    {#each Object.keys(childrenAges) as childrenAgeKey}
                        <option value={childrenAgeKey}>
                            {childrenAges[childrenAgeKey]}
                        </option>
                    {/each}
                </select>
            </div>
        {/each}
    </div>
{/if}
