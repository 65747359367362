<script lang="ts">
    import Drawer from '$saga/components/Drawer.svelte';

    export let label, airportArrivalName, dict;
</script>

<Drawer titleLabel={dict.header}>
    <div slot="triggerlink">
        <div class="cursor-pointer underline hover:no-underline">
            {label}
        </div>
    </div>

    <div class="flex flex-col gap-m px-m py-s desktop:px-3xl desktop:py-m">
        <div
            class="border-b border-solid border-border-quiet pb-xs text-[20px] font-semibold"
        >
            {dict.title}
            {airportArrivalName}
        </div>
        <p class="text-justify">
            {dict.description}
            {airportArrivalName}
        </p>
    </div>
</Drawer>
