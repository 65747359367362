<script>
    import dayjs from 'dayjs';
    import { createDatePicker } from '@melt-ui/svelte';
    import { parseDate } from '@internationalized/date';
    import { VpPlayIcon } from '@decathlon-experience/icons/svelte';

    import {
        findFirstAvailableMonth,
        formatTourDate,
        formatTourDate2,
    } from '$saga/utils/date';
    import cn from '$common/utils/cn';
    import { TOUR_TYPE_FIT, TOUR_TYPE_GIR } from '$saga/constants/tour';
    import ChevronRightIcon from '$saga/icons/ChevronRightIcon.svelte';
    import Button from '$saga/components/Button.svelte';
    import CalendarPicker from '$saga/components/CalendarPicker.svelte';
    import { dateValue } from '$saga/store/tourFitDate';

    export let dates;
    export let tourType;
    export let handleNextStep;
    export let closedSales;

    const {
        elements: { calendar, cell, grid, heading, nextButton, prevButton },
        states: { value, months, headingValue, weekdays },
        helpers: { isDateDisabled, isDateUnavailable, setMonth, setYear },
    } = createDatePicker({
        locale: 'fr',
        name: 'selectedDate',
        numberOfMonths: 2,
        minValue: parseDate(dayjs().add(1, 'day').format('YYYY-MM-DD')),
        isDateDisabled: (date) => {
            let isDisabled = false;
            for (const closedDate of closedSales) {
                const d = dayjs(date.toString());
                // '[]' is inclusive start+end day dates
                if (
                    d.isBetween(
                        closedDate.startDate,
                        closedDate.endDate,
                        'day',
                        '[]',
                    )
                )
                    isDisabled = true;
            }

            return isDisabled;
        },
        value: dateValue,
    });

    $: {
        // find the closest date available, if not in current month then set
        // to the closest date's month
        // dayjs starts at 0, melt-ui at 1
        // + one more offset to display the 1st available month on the left
        const firstAvailableDate = findFirstAvailableMonth(closedSales);
        setYear(firstAvailableDate.year());
        setMonth(firstAvailableDate.month() + 1);
    }

    const handleGirDateSelection = (selectedItem) => {
        // TODO .Ts this file
        if (selectedItem?.date1?.date != null)
            handleNextStep(
                dayjs(selectedItem.date1.date).format('YYYY-MM-DD'),
                {
                    priceWithCustomerCarbonContribution: selectedItem.hasRebate
                        ? selectedItem.rebatedPriceWithCustomerCarbonContribution
                        : selectedItem.priceWithCustomerCarbonContribution,
                    remainingStock: selectedItem?.remaining_stock ?? '',
                    statusName: selectedItem?.statusName ?? '',
                },
            );
    };

    const handleFitDateSelection = () => {
        // format ebooking vs freequote
        handleNextStep(dayjs($value).format('YYYY-MM-DD'), null);
    };
</script>

{#if tourType === TOUR_TYPE_GIR}
    <div class="pb-2xl pt-2xl">
        {#each dates as date}
            <button
                type="button"
                class={'flex w-full border-b border-solid border-border-quiet py-xs text-sm'}
                disabled={date.isFull}
                on:click={() => !date.isFull && handleGirDateSelection(date)}
            >
                <span class="ml-xl flex w-full flex-col gap-1 text-left">
                    <span class={date.isFull && 'opacity-[0.38]'}>
                        du <span class="font-semibold"
                            >{formatTourDate(date.date1.date)}</span
                        >
                        au
                        <span class="font-semibold"
                            >{formatTourDate2(date.date2.date)}</span
                        >
                    </span>
                    {#if date.isFull}
                        <span class="font-semibold text-content-brand">
                            Complet
                        </span>
                    {:else}
                        <span class="text-content-brand">
                            <span class="font-semibold">{date.statusName}</span>
                            {#if date.remaining_stock > 0}
                                - {date.remaining_stock} places dispo
                            {/if}
                        </span>
                    {/if}
                    <span
                        class={cn(
                            'flex items-center gap-xs',
                            date.isFull && 'opacity-[0.38]',
                        )}
                    >
                        {#if date.hasRebate}
                            <span class="line-through">
                                {date.priceWithCustomerCarbonContribution}€
                            </span>
                            <span
                                class="w-fit bg-container-commercial-catchy p-2xs text-xs"
                            >
                                {date.rebateAmount}
                            </span>

                            <span class="pl-xs font-semibold">
                                {date.rebatedPriceWithCustomerCarbonContribution}€
                            </span>
                        {:else}
                            <span class="font-semibold">
                                {date.priceWithCustomerCarbonContribution}€
                            </span>
                        {/if}
                    </span>
                </span>
                <span class="m-auto h-full p-m">
                    <ChevronRightIcon
                        class={cn(
                            'size-xl fill-content-neutral',
                            date.isFull && 'fill-content-quiet opacity-[0.38]',
                        )}
                    />
                </span>
            </button>
        {/each}
    </div>
{:else if tourType === TOUR_TYPE_FIT}
    <!-- 90px spacing for footer bottom button -->
    <div
        class="relative flex h-full w-full items-center justify-center pb-[90px]"
    >
        <CalendarPicker
            class="py-m"
            {calendar}
            {prevButton}
            {nextButton}
            {heading}
            {headingValue}
            {months}
            {grid}
            {weekdays}
            {isDateDisabled}
            {isDateUnavailable}
            {cell}
            isMultipleMonths
            monthsOrientation="vertical"
        />
        <!-- Footer -->
        <div
            class="fixed bottom-0 left-0 flex w-full justify-center border-t border-solid border-border-quiet bg-white p-m pb-xl"
        >
            <Button
                intent={$value ? 'primary' : 'disabled'}
                class="w-full"
                disabled={$value == null}
                on:click={handleFitDateSelection}
                endIcon={VpPlayIcon}
            >
                Continuer
            </Button>
        </div>
    </div>
{/if}
