import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class extends Controller {
    static targets = ['radio'];

    check(event) {
        this.radioTargets.forEach((radioTarget) => {
            radioTarget.classList.remove('form__radio--active');
        });

        event.currentTarget.classList.add('form__radio--active');

        const radio = event.currentTarget.getElementsByTagName('input')[0];
        radio.checked = true;

        event.currentTarget.dispatchEvent(new Event('input'));
    }
}
