import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import type { DateValue } from '@internationalized/date';
import {
    MinPriceDays,
    MinPriceMonth,
    Quantile,
    VisibleDate,
} from '$saga/views/Tour/VisibleDates/types';
// $src impossible, weird compilation bug?
import { formatCurrency } from '../../../utils/price';

// GIR only
export const getCurrentOrNextAvailableMonth = (
    minPricePerMonths: MinPriceMonth[],
    visibleDates: VisibleDate[],
): Dayjs => {
    let nextAvailableMonth = dayjs();
    // if there is a non-null price for the current month
    if (
        minPricePerMonths.find(
            (minPriceMonth) =>
                !isMonthDatesFull(visibleDates, minPriceMonth.date) &&
                minPriceMonth.date.isSame(nextAvailableMonth, 'month') &&
                minPriceMonth.price != null,
        )
    ) {
        return nextAvailableMonth;
    } else {
        // find the next month with non-null price
        for (let i = 0; i < minPricePerMonths.length; i++) {
            if (
                !isMonthDatesFull(visibleDates, minPricePerMonths[i].date) &&
                minPricePerMonths[i].date.isAfter(nextAvailableMonth, 'month')
            ) {
                nextAvailableMonth = minPricePerMonths[i].date;
                break;
            }
        }
    }
    return nextAvailableMonth;
};

export const isDateRemainingStockLast = (date: VisibleDate) =>
    date.remaining_stock === 1;
export const isDateRemainingStockAvailable = (date: VisibleDate) =>
    date.remaining_stock > 0;
export const isSelectedMonth = (date: Dayjs, currentActiveMonth: Dayjs) =>
    currentActiveMonth.isSame(date, 'month');

export const isMonthDatesFull = (visibleDates: VisibleDate[], date: Dayjs) =>
    visibleDates
        ?.filter(
            (visibleDate) =>
                dayjs(visibleDate.date1.date).month() === date.month(),
        )
        .every((visibleDate) => visibleDate.isFull);

export const getQuantileSizeClass = (quantile?: Quantile) => {
    switch (quantile) {
        case 'high':
            return 'border-status-warning';
        case 'low':
            return 'border-status-positive';
        case 'medium':
            return 'border-commercial-catchy';
        case 'none':
        default:
            return 'border-none';
    }
};

export const hasQuantile = (minPricePerMonths: MinPriceMonth[]) => {
    return minPricePerMonths.every(
        (minPricePerMonth) => minPricePerMonth?.quantile !== 'none',
    );
};

export const getCurrentDayPriceAmount = (
    minPricePerDays: MinPriceDays,
    date: DateValue,
) => {
    const priceDay =
        minPricePerDays[dayjs(date.toString()).format('YYYY-MM-DD')];
    if (priceDay)
        return formatCurrency(priceDay.amount / 100, false).replace(/\s/g, '');
    else return '-';
};
