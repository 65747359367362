<script lang="ts">
    import { TOUR_TYPE_GIR, TYPEBOOKING_EBOOKING } from '$saga/constants/tour';
    import AdultsInput from './AdultsInput.svelte';
    import ChildrenInput from './ChildrenInput.svelte';
    import { formatTourDate, formatTourDate2 } from '$saga/utils/date';
    import dayjs from 'dayjs';

    export let tourType;
    export let bookingType;
    export let isTravelAloneAuthorized;
    export let minAgeAllowed;
    export let maxAgeAllowed;
    export let formValues;
    export let childrenAges;
    export let ageRules;
    export let datesInfos;
    export let tourDuration;

    const isEbookingType = bookingType === TYPEBOOKING_EBOOKING;
    const isGir = tourType === TOUR_TYPE_GIR;

    $: maxAdultsRuleValue =
        isEbookingType && isGir
            ? formValues.selectedDate1 &&
              datesInfos.remainingStock - formValues.children
            : null;
    $: maxChildrenRuleValue = isGir
        ? datesInfos.remainingStock - formValues.adults
        : null;

    $: endDate = formValues.selectedDate
        ? dayjs(formValues.selectedDate)
              .add(tourDuration - 1, 'days')
              .format('YYYY-MM-DD')
        : null;
</script>

<div class="mx-xl flex flex-col gap-xl pb-2xl pt-2xl">
    <div
        class="mx-auto flex w-fit justify-center gap-2xs border-b-[1.5px] border-solid border-bord-quiet px-xs pb-xs"
    >
        <span> Du </span>
        <span class="font-semibold"
            >{formatTourDate(formValues.selectedDate)}</span
        >
        <span> au </span>
        <span>
            <span class="font-semibold">{formatTourDate2(endDate)}</span>
        </span>
    </div>
    <!-- Existing adult/child selector content here -->
    <AdultsInput
        {isTravelAloneAuthorized}
        {minAgeAllowed}
        {maxAgeAllowed}
        {tourType}
        {bookingType}
        bind:formValues
        {ageRules}
        maxRuleValue={maxAdultsRuleValue}
        dateStatusName={datesInfos?.statusName ?? ''}
    />
    {#if bookingType === TYPEBOOKING_EBOOKING}
        <ChildrenInput
            {minAgeAllowed}
            {maxAgeAllowed}
            bind:formValues
            {ageRules}
            {childrenAges}
            maxRuleValue={maxChildrenRuleValue}
        />
    {/if}
</div>
