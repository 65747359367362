<script lang="ts">
    import { Dict } from '$saga/views/Tour/VisibleDates/types';

    export let searchUrl: string, dict: Dict;
</script>

<div
    class="border-content-brand my-sm flex justify-between border border-solid bg-commercial-neutral p-sm text-content-brand"
>
    <div class="flex items-center gap-xs">
        <span>{dict.sale.closed}</span>
    </div>
    <a href={searchUrl} class="flex items-center gap-xs underline">
        {dict.sale.see_travels}
    </a>
</div>
