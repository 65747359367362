<script lang="ts">
    import Drawer from '$saga/components/Drawer.svelte';
    import { VpInformationIcon } from '@decathlon-experience/icons/svelte';

    export let dict;

    let hasStep2 =
        dict.step2Label ||
        dict.step2Description ||
        dict.step2Details ||
        dict.step2TransfertDescription ||
        dict.step2PriceDescription;
</script>

<Drawer titleLabel={dict.header}>
    <div slot="triggerlink">
        <div
            class="flex cursor-pointer items-center gap-2xs underline hover:no-underline"
        >
            <VpInformationIcon class="size-m" />En savoir plus
        </div>
    </div>

    <div class="flex flex-col gap-m px-m py-s desktop:px-3xl desktop:py-m">
        <!-- step 1 -->
        <div
            class="border-b border-solid border-border-quiet pb-xs text-xl font-semibold"
        >
            {dict.step1}
        </div>
        {#if dict.step1Label}
            <div class="font-semibold">{dict.step1Label}</div>
        {/if}
        {#if dict.step1Description}
            <div class="is-rt text-justify">
                <!-- eslint-disable-next-line svelte/no-at-html-tags -->
                {@html dict.step1Description}
            </div>
        {/if}

        <!-- step 2/3 -->
        {#if hasStep2}
            <div
                class="border-b border-solid border-border-quiet pb-xs text-[20px] font-semibold"
            >
                {dict.step2}
            </div>
            {#if dict.step2Label}
                <div class="font-semibold">
                    {dict.step2Label}
                </div>
            {/if}

            <div class="flex flex-col gap-xs text-justify">
                {#if dict.step2Description}
                    <div>{dict.step2Description}</div>
                {/if}

                {#if dict.step2Details}
                    <div>
                        {dict.step2Details}
                    </div>
                {/if}

                {#if dict.step2TransfertDescription}
                    <div class="is-rt">
                        <!-- eslint-disable-next-line svelte/no-at-html-tags -->
                        {@html dict.step2TransfertDescription}
                    </div>
                {/if}

                {#if dict.step2PriceDescription}
                    <!-- eslint-disable-next-line svelte/no-at-html-tags -->
                    <div class="is-rt">{@html dict.step2PriceDescription}</div>
                {/if}
            </div>
        {/if}

        <!-- step 3 -->
        <div
            class="border-b border-solid border-border-quiet pb-xs text-[20px] font-semibold"
        >
            {hasStep2 ? dict.step3 : dict.step2}
        </div>
        <div class="font-semibold">{dict.step3Label}</div>

        <div class="flex flex-col gap-xs">
            {#if dict.step3Description}
                <div class="text-justify">{dict.step3Description}</div>
            {/if}
            {#if dict.step3Details}
                <!-- eslint-disable-next-line svelte/no-at-html-tags -->
                <div class="is-rt">{@html dict.step3Details}</div>
            {/if}
        </div>
    </div>
</Drawer>
