<script lang="ts">
    import dayjs from 'dayjs';

    import VisibleDatesMobile from '$saga/views/Tour/VisibleDates/Gir/VisibleDatesMobile.svelte';
    import VisibleDatesDesktop from '$saga/views/Tour/VisibleDates/Gir/VisibleDatesDesktop.svelte';
    import {
        Dict,
        MinPriceMonth,
        VisibleDate,
    } from '$saga/views/Tour/VisibleDates/types';

    export let tourDatesInfo: string,
        visibleDates: VisibleDate[],
        dict: Dict,
        searchUrl: string,
        isBookable: boolean,
        cheapestMonthlyPrices;

    let minPricePerMonths: MinPriceMonth[];

    // transform cheapestMonthlyPrices into an array of object container a dayjs date, and a number "price"
    $: {
        // key is the date
        minPricePerMonths = Object.keys(cheapestMonthlyPrices).map((key) => ({
            date: dayjs(key),
            price: cheapestMonthlyPrices[key]?.amount,
            quantile: cheapestMonthlyPrices[key]?.quantile,
        }));
    }
</script>

<!--Mobile -->
<VisibleDatesMobile
    {tourDatesInfo}
    {visibleDates}
    {dict}
    {searchUrl}
    {minPricePerMonths}
    {isBookable}
/>

<!--Desktop-->
<VisibleDatesDesktop
    {tourDatesInfo}
    {visibleDates}
    {dict}
    {searchUrl}
    {minPricePerMonths}
    {isBookable}
/>
