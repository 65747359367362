<script lang="ts">
    import dayjs from 'dayjs';
    import { VpPriceTagIcon } from '@decathlon-experience/icons/svelte';

    import { DateRange } from '$saga/utils/date';
    import { MinPriceMonth } from '$saga/views/Tour/VisibleDates/types';
    import VisibleDatesDesktop from '$saga/views/Tour/VisibleDates/Fit/VisibleDatesDesktop.svelte';
    import VisibleDatesMobile from '$saga/views/Tour/VisibleDates/Fit/VisibleDatesMobile.svelte';
    import UnavailableTour from '$saga/views/Tour/VisibleDates/UnavailableTour.svelte';

    export let closedSales,
        dict,
        disabled: boolean,
        searchUrl: string,
        cheapestMonthlyPrices,
        cheapestDailyPrices,
        isBookable: boolean;

    let closedSalesFormatted: DateRange[];
    $: {
        const parsedClosedSales = JSON.parse(closedSales);
        closedSalesFormatted = parsedClosedSales.map(
            (closedSale: { startDate: string; endDate: string }) => {
                return {
                    startDate: dayjs(closedSale.startDate),
                    endDate: dayjs(closedSale.endDate),
                };
            },
        );
    }
    let minPricePerMonths: MinPriceMonth[] = [];
    // transform cheapestMonthlyPrices into an array of object container a dayjs date, and a number "price"
    $: {
        // key is the date
        minPricePerMonths = Object.keys(cheapestMonthlyPrices).map((key) => ({
            date: dayjs(key),
            price: cheapestMonthlyPrices[key]?.amount,
            quantile: cheapestMonthlyPrices[key]?.quantile,
        }));
    }
</script>

<h2 class="pb-m text-[32px] font-semibold text-content-neutral">
    {dict.dates.title}
</h2>
<!-- If FIT is disabled -->
{#if disabled}
    <UnavailableTour {dict} {searchUrl} />
{:else}
    <div class="w-full bg-white p-s text-content-neutral desktop:p-m">
        <div class="flex flex-col gap-m">
            <h3 class="text-[22px] font-semibold">{dict.dates.start}</h3>
            <span class="text-justify">{dict.dates.description}</span>
            <span class="flex gap-xs">
                <VpPriceTagIcon class="size-xl" />
                {dict.dates.price_per_age}
            </span>
            <span class="text-center font-semibold">
                {dict.dates.months_select}
            </span>

            <div class="hidden desktop:block">
                <VisibleDatesDesktop
                    {minPricePerMonths}
                    minPricePerDays={cheapestDailyPrices}
                    closedSales={closedSalesFormatted}
                    {isBookable}
                />
            </div>

            <div class="desktop:hidden">
                <VisibleDatesMobile
                    {minPricePerMonths}
                    minPricePerDays={cheapestDailyPrices}
                    closedSales={closedSalesFormatted}
                    {isBookable}
                />
            </div>
        </div>
    </div>
{/if}
