<script lang="ts">
    import dayjs from 'dayjs';
    import {
        VpPriceTagIcon,
        VpArrowDownIcon,
        VpArrowUpIcon,
    } from '@decathlon-experience/icons/svelte';
    import { createPagination } from '@melt-ui/svelte';
    import { fade } from 'svelte/transition';

    import cn from '$common/utils/cn';
    import { formatCurrency } from '$saga/utils/price';
    import { formatTourDate, formatTourDate2 } from '$saga/utils/date';
    import {
        getCurrentOrNextAvailableMonth,
        hasQuantile,
        isDateRemainingStockAvailable,
        isDateRemainingStockLast,
    } from '$saga/views/Tour/VisibleDates/utils';
    import Button from '$saga/components/Button.svelte';
    import {
        Dict,
        MinPriceMonth,
        VisibleDate,
    } from '$saga/views/Tour/VisibleDates/types';
    import MinPricePerMonthsList from '$saga/views/Tour/VisibleDates/MinPricePerMonthsList.svelte';
    import AgeRangePricesDrawer from '$saga/views/Tour/VisibleDates/AgeRangePricesDrawer.svelte';
    import Tooltip from '$saga/components/Tooltip.svelte';
    import CalendarLegend from '$saga/views/Tour/VisibleDates/Fit/CalendarLegend.svelte';
    import UnavailableTour from '$saga/views/Tour/VisibleDates/UnavailableTour.svelte';

    export let tourDatesInfo: string,
        visibleDates: VisibleDate[],
        dict: Dict,
        searchUrl: string,
        minPricePerMonths: MinPriceMonth[],
        isBookable: boolean;

    let isShowingMore = false;
    let currentActiveMonth = getCurrentOrNextAvailableMonth(
        minPricePerMonths,
        visibleDates,
    );
    let filteredDatesByMonth: VisibleDate[] = [];

    const {
        elements: { root, prevButton, nextButton },
        states: { range, totalPages, page },
    } = createPagination({
        count: minPricePerMonths.length - 1,
        perPage: 9,
    });

    $: {
        filteredDatesByMonth = visibleDates.filter((visibleDate) =>
            dayjs(visibleDate.date1.date).isSame(currentActiveMonth, 'month'),
        );
        // reset showmore button at every currentActiveMonth change
        isShowingMore = false;
    }

    const getDateTooltip = (date: VisibleDate) => {
        if (date.isFull) return dict.dates.full;
        else if (date.isGuaranteed) {
            return dict.status.confirm;
        }
        return dict.status.available.replace(
            '%minPax%',
            date.countPaxNeededToConfirm.toString(),
        );
    };
</script>

<div class="hidden desktop:block">
    <h2 class="pb-sm text-[32px] font-semibold text-content-neutral">
        {dict.dates.title}
    </h2>
    {#if visibleDates.length > 0}
        <div class="w-full bg-white p-sm text-content-neutral">
            <div class="flex flex-col gap-m">
                <h3 class="text-[22px] font-semibold">{dict.dates.start}</h3>
                <span class="text-justify">{dict.dates.description}</span>
                {#if visibleDates.find((date) => date.ageRangePrices.length > 1) != null}
                    <div class="flex gap-xs">
                        <VpPriceTagIcon class="size-xl" />
                        {dict.dates.price_per_age}
                    </div>
                {/if}
                <span class="text-center font-semibold">
                    {dict.dates.months_select}
                </span>
                <MinPricePerMonthsList
                    {root}
                    {prevButton}
                    {nextButton}
                    {page}
                    {range}
                    bind:currentActiveMonth
                    {totalPages}
                    {minPricePerMonths}
                    {visibleDates}
                />
                {#if hasQuantile(minPricePerMonths)}
                    <CalendarLegend />
                {/if}
            </div>

            <form autocomplete="off" class="mt-2xl">
                <table
                    class="travel-tour-dates-table w-full border-separate border-spacing-y-xs"
                >
                    <tbody>
                        {#each visibleDates as date}
                            <tr
                                data-travel-tour-dates-datarow
                                on:click={(event) => {
                                    const target =
                                        event.currentTarget.querySelector(
                                            'input[name="startDateTable"]',
                                        );
                                    // avoid event to be retriggered by the input
                                    if (event?.target?.tagName !== 'INPUT') {
                                        target.click();
                                    }
                                }}
                                class={cn(
                                    !date.isFull && 'cursor-pointer',
                                    !isShowingMore &&
                                        filteredDatesByMonth.findIndex(
                                            (filteredDate) =>
                                                date.id === filteredDate.id,
                                        ) > 4 &&
                                        'hidden',
                                    !dayjs(date.date1.date).isSame(
                                        currentActiveMonth,
                                        'month',
                                    ) && 'hidden',
                                )}
                            >
                                <td
                                    class="w-[1%] whitespace-nowrap border border-r-0 border-solid border-bord-primary p-xs text-center align-middle"
                                >
                                    <div
                                        class={cn(
                                            'p-xs',
                                            date.isFull && 'opacity-[0.38]',
                                        )}
                                    >
                                        <input
                                            name="startDateTable"
                                            type="radio"
                                            value={dayjs(
                                                date.date1.date,
                                            ).format('YYYY-MM-DD')}
                                            disabled={date.isFull}
                                        />
                                    </div>
                                </td>
                                <td
                                    class="w-[1%] whitespace-nowrap border border-x-0 border-solid border-bord-primary p-xs text-left align-middle"
                                    data-date={dayjs(date.date1.date).format(
                                        'YYYY-MM-DD',
                                    )}
                                >
                                    <div
                                        class={cn(
                                            date.isFull && 'opacity-[0.38]',
                                        )}
                                    >
                                        Du <span class="font-semibold">
                                            {formatTourDate(date.date1.date)}
                                        </span>
                                        au
                                        <span class="font-semibold">
                                            {formatTourDate2(date.date2.date)}
                                        </span>
                                    </div>
                                </td>

                                <td
                                    class="travel-tour-dates__status border border-x-0 border-solid border-bord-primary p-xs"
                                    data-status={date.statusName}
                                    data-date={dayjs(date.date1.date).format(
                                        'YYYY-MM-DD',
                                    )}
                                >
                                    {#if date.isFull}
                                        <div class="flex flex-col gap-2xs">
                                            <span class="w-fit font-semibold">
                                                <Tooltip
                                                    tooltipContentClass="bg-content-quiet text-white"
                                                    tooltipContent={getDateTooltip(
                                                        date,
                                                    )}
                                                    tooltipLabel={date.statusName}
                                                    hasArrow={true}
                                                />
                                            </span>
                                            <span
                                                class="text-xs uppercase opacity-[0.38]"
                                            >
                                                Indisponible
                                            </span>
                                        </div>
                                    {/if}
                                    {#if date.isGuaranteed}
                                        <div class="flex flex-col gap-2xs">
                                            <span class="font-semibold">
                                                <Tooltip
                                                    tooltipContentClass="bg-content-quiet text-white"
                                                    tooltipContent={getDateTooltip(
                                                        date,
                                                    )}
                                                    tooltipLabel={date.statusName}
                                                    hasArrow={true}
                                                />
                                            </span>
                                            {#if isDateRemainingStockAvailable(date)}
                                                <span
                                                    class="text-xs uppercase text-content-brand"
                                                >
                                                    {date.remaining_stock} Place{!isDateRemainingStockLast(
                                                        date,
                                                    )
                                                        ? 's'
                                                        : ''} disponible{!isDateRemainingStockLast(
                                                        date,
                                                    )
                                                        ? 's'
                                                        : ''}
                                                </span>
                                            {/if}
                                        </div>
                                    {/if}
                                    {#if date.isAvailable}
                                        <div class="flex flex-col gap-2xs">
                                            <span class="font-semibold">
                                                <Tooltip
                                                    tooltipContentClass="bg-content-quiet text-white"
                                                    tooltipContent={getDateTooltip(
                                                        date,
                                                    )}
                                                    tooltipLabel={date.statusName}
                                                    hasArrow={true}
                                                />
                                            </span>
                                            {#if isDateRemainingStockAvailable(date)}
                                                <span
                                                    class="text-xs uppercase text-content-brand"
                                                >
                                                    {date.remaining_stock} place{!isDateRemainingStockLast(
                                                        date,
                                                    )
                                                        ? 's'
                                                        : ''} dispo
                                                </span>
                                            {/if}
                                        </div>
                                    {/if}
                                </td>

                                <td
                                    class="w-[1%] whitespace-nowrap border border-l-0 border-solid border-bord-primary p-xs text-left align-middle"
                                >
                                    <div
                                        class={cn(
                                            'flex items-center justify-end gap-xs',
                                            date.isFull && 'opacity-[0.38]',
                                        )}
                                    >
                                        {#if !isBookable}
                                            <span class="text-xs"
                                                >À partir de</span
                                            >
                                        {/if}
                                        {#if date.hasRebate}
                                            <span
                                                class="travel-tour-dates__price font-semibold line-through"
                                            >
                                                {formatCurrency(
                                                    date.priceWithCustomerCarbonContribution,
                                                )}
                                            </span>
                                            <span
                                                class="bg-container-commercial-catchy p-2xs text-xs"
                                            >
                                                {date.rebateAmount}
                                            </span>
                                            <span
                                                class="travel-tour-dates__price text-[20px] font-semibold"
                                                data-date={dayjs(
                                                    date.date1.date,
                                                ).format('YYYY-MM-DD')}
                                                data-rebatedPrice={date.rebatedPriceWithCustomerCarbonContribution}
                                                data-formattedRebatedPrice={formatCurrency(
                                                    date.rebatedPriceWithCustomerCarbonContribution ??
                                                        0,
                                                )}
                                                data-rebatePercentage={date.rebateAmount}
                                                data-price={date.priceWithCustomerCarbonContribution}
                                                data-formattedPrice={formatCurrency(
                                                    date.priceWithCustomerCarbonContribution,
                                                )}
                                            >
                                                {formatCurrency(
                                                    date.rebatedPriceWithCustomerCarbonContribution ??
                                                        0,
                                                )}
                                            </span>
                                        {:else}
                                            <span
                                                class="travel-tour-dates__price text-xl font-semibold"
                                                data-date={dayjs(
                                                    date.date1.date,
                                                ).format('YYYY-MM-DD')}
                                                data-price={date.priceWithCustomerCarbonContribution}
                                                data-formattedPrice={formatCurrency(
                                                    date.priceWithCustomerCarbonContribution,
                                                )}
                                            >
                                                {formatCurrency(
                                                    date.priceWithCustomerCarbonContribution,
                                                )}
                                            </span>
                                        {/if}
                                        {#if date.ageRangePrices.length > 1}
                                            <AgeRangePricesDrawer
                                                disabled={date.isFull}
                                                ageRangePrices={date.ageRangePrices}
                                            />
                                        {/if}
                                    </div>
                                </td>
                            </tr>
                        {:else}
                            <div
                                in:fade
                                class="flex flex-col gap-xs mt-xs text-center"
                            >
                                <span class="font-semibold">
                                    {dict.dates.empty.title}
                                </span>
                                <span class="text-sm">
                                    {dict.dates.empty.description}
                                </span>
                            </div>
                        {/each}
                    </tbody>
                </table>
            </form>

            {#if filteredDatesByMonth.length > 5}
                <div class="my-m flex items-center justify-center">
                    <Button
                        intent="secondary"
                        size="small"
                        shape="rounded"
                        class="text-content-brand"
                        endIcon={!isShowingMore
                            ? VpArrowDownIcon
                            : VpArrowUpIcon}
                        on:click={() => (isShowingMore = !isShowingMore)}
                    >
                        {#if !isShowingMore}
                            {dict.dates.showmore}
                        {:else}
                            {dict.dates.showless}
                        {/if}
                    </Button>
                </div>
            {/if}

            {#if tourDatesInfo}
                <div class="info bg-white p-sm italic text-content-neutral">
                    <h3 class="pb-sm text-[22px] text-content-neutral">
                        {dict.dates.dates_info}
                    </h3>
                    <div class="is-rt text-content-quiet">
                        <!-- eslint-disable-next-line svelte/no-at-html-tags -->
                        {@html tourDatesInfo}
                    </div>
                </div>
            {/if}
        </div>
    {:else}
        <UnavailableTour {dict} {searchUrl} />
    {/if}
</div>
