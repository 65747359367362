<script lang="ts">
    import { createTabs } from '@melt-ui/svelte';

    import CounterHigh from '$saga/icons/tour/CounterHigh.svelte';
    import CounterLow from '$saga/icons/tour/CounterLow.svelte';
    import { TourSport } from '$saga/views/Tour/features/types';
    import Drawer from '$saga/components/Drawer.svelte';
    import cn from '$common/utils/cn';
    import LevelIndicator from '$saga/views/Tour/features/LevelIndicator.svelte';

    export let physicalDifficulty: number,
        physicalDifficultyLabel: string,
        titleLabel: string,
        tourSport1: TourSport | null,
        tourSport2: TourSport | null,
        tourSport1Svg: string,
        tourSport2Svg: string | null;

    const isSelectedDifficulty = (difficulty: number) =>
        difficulty === physicalDifficulty;

    const tourSportAllLevelsContent = {
        title: 'Tous niveaux',
        descr: tourSport1?.levelAllLevels,
    };

    const tourSport1LevelContent = [
        {
            title: 'Débutant',
            descr: tourSport1?.levelBeginner,
        },
        {
            title: 'Intermédiaire',
            descr: tourSport1?.levelIntermediate,
        },
        {
            title: 'Confirmé',
            descr: tourSport1?.levelConfirmed,
        },
        {
            title: 'Avancé',
            descr: tourSport1?.levelAdvanced,
        },
        {
            title: 'Expert',
            descr: tourSport1?.levelExpert,
        },
    ];
    const tourSport2LevelContent = [
        {
            title: 'Débutant',
            descr: tourSport2?.levelBeginner,
        },
        {
            title: 'Intermédiaire',
            descr: tourSport2?.levelIntermediate,
        },
        {
            title: 'Confirmé',
            descr: tourSport2?.levelConfirmed,
        },
        {
            title: 'Avancé',
            descr: tourSport2?.levelAdvanced,
        },
        {
            title: 'Expert',
            descr: tourSport2?.levelExpert,
        },
    ];

    // Tabs
    const {
        elements: { root, list, content, trigger },
        states: { value },
    } = createTabs({
        defaultValue: 'tab-1',
    });
    const triggers = [
        { id: 'tab-1', title: tourSport1?.name, svg: tourSport1Svg },
        ...(tourSport2 != null
            ? [{ id: 'tab-2', title: tourSport2?.name, svg: tourSport2Svg }]
            : []),
    ];
</script>

{#if titleLabel !== ''}
    <div
        class="mr-m flex w-full max-w-[120px] items-center medium:mr-xl medium:max-w-[170px]"
    >
        {#if physicalDifficulty > 3}
            <CounterHigh class="mr-m size-l fill-content-brand" />
        {:else}
            <CounterLow class="mr-m size-l fill-content-brand" />
        {/if}
        <span class="name font-semibold">{titleLabel}</span>
    </div>
{/if}

<Drawer titleLabel="Niveaux">
    <div slot="triggerlink">
        <div
            class="cursor-pointer text-content-brand underline hover:no-underline"
        >
            {physicalDifficultyLabel}
        </div>
    </div>

    <div {...$root} use:root class={cn('w-full')}>
        <div
            {...$list}
            use:list
            class="flex gap-m overflow-x-auto px-m desktop:justify-between desktop:px-3xl"
            aria-label="Niveaux"
        >
            <!-- display tabs or not -->
            {#if triggers.length > 1}
                {#each triggers as triggerItem}
                    <div class="flex-1 self-end">
                        <div
                            class={cn(
                                'flex cursor-pointer gap-2xs p-xs transition-colors duration-[0.25s]',
                                $value === triggerItem.id &&
                                    'text-content-brand',
                            )}
                            {...$trigger(triggerItem.id)}
                            use:trigger
                        >
                            <div
                                class={cn(
                                    'self-center',
                                    $value === triggerItem.id &&
                                        'fill-content-brand',
                                )}
                            >
                                <!-- eslint-disable-next-line svelte/no-at-html-tags -->
                                {@html triggerItem.svg}
                            </div>
                            <div class="text-[20px] font-semibold">
                                {triggerItem.title}
                            </div>
                        </div>
                        <hr
                            class={cn(
                                'border-t-1 w-full border-solid border-border-quiet transition-colors duration-[0.25s]',
                                $value === triggerItem.id &&
                                    'border-t-2 border-border-brand',
                            )}
                        />
                    </div>
                {/each}
            {:else}
                <div class="flex-1 self-end">
                    <div
                        class={cn(
                            'flex gap-2xs py-xs transition-colors duration-[0.25s]',
                        )}
                    >
                        <div class={cn('self-center')}>
                            <!-- eslint-disable-next-line svelte/no-at-html-tags -->
                            {@html tourSport1Svg}
                        </div>
                        <div class="text-[20px] font-semibold">
                            {triggers[0].title}
                        </div>
                    </div>
                    <hr
                        class={cn(
                            'border-t-1 w-full border-solid border-border-quiet transition-colors duration-[0.25s]',
                        )}
                    />
                </div>
            {/if}
        </div>

        <!--sport 1 -->
        <div {...$content('tab-1')} use:content>
            <div class="mb-[15vh] mt-m flex flex-col gap-m px-l desktop:px-3xl">
                <!--ALL LEVELS -->
                {#if isSelectedDifficulty(0)}
                    <div
                        class="flex flex-col gap-xs border border-solid border-border-brand p-xs"
                    >
                        <div class="flex justify-between">
                            <div
                                class="text-[20px] font-semibold text-content-brand"
                            >
                                {tourSportAllLevelsContent.title}
                            </div>
                        </div>
                        <div class="text-justify text-content-brand">
                            {tourSportAllLevelsContent.descr}
                        </div>
                    </div>
                {:else}
                    {#each Array.from(Array(5)) as _, index}
                        <div
                            class={cn(
                                'flex flex-col gap-xs',
                                isSelectedDifficulty(index + 1) &&
                                    'border border-solid border-border-brand p-xs',
                            )}
                        >
                            <div class="flex justify-between">
                                <div
                                    class={cn(
                                        isSelectedDifficulty(index + 1) &&
                                            'text-content-brand',
                                        'text-[20px] font-semibold',
                                    )}
                                >
                                    {tourSport1LevelContent[index].title}
                                </div>
                                <LevelIndicator
                                    difficultyLevel={index}
                                    isCurrentDifficultyLevel={isSelectedDifficulty(
                                        index + 1,
                                    )}
                                />
                            </div>
                            <div
                                class={cn(
                                    'text-justify',
                                    isSelectedDifficulty(index + 1) &&
                                        'text-content-brand',
                                )}
                            >
                                {tourSport1LevelContent[index].descr}
                            </div>
                        </div>
                    {/each}
                {/if}
            </div>
        </div>

        <!--sport 2 -->
        <div {...$content('tab-2')} use:content>
            <div class="mb-[15vh] mt-m flex flex-col gap-m px-l desktop:px-3xl">
                {#if isSelectedDifficulty(0)}
                    <div
                        class="flex flex-col gap-xs border border-solid border-border-brand p-xs"
                    >
                        <div class="flex justify-between">
                            <div
                                class="text-[20px] font-semibold text-content-brand"
                            >
                                {tourSportAllLevelsContent.title}
                            </div>
                        </div>
                        <div class="text-justify text-content-brand">
                            {tourSportAllLevelsContent.descr}
                        </div>
                    </div>
                {:else}
                    {#each Array.from(Array(5)) as _, index}
                        <div
                            class={cn(
                                'flex flex-col gap-xs',
                                isSelectedDifficulty(index + 1) &&
                                    'border border-solid border-border-brand p-xs',
                            )}
                        >
                            <div class="flex justify-between">
                                <div
                                    class={cn(
                                        isSelectedDifficulty(index + 1) &&
                                            'text-content-brand',
                                        'text-[20px] font-semibold',
                                    )}
                                >
                                    {tourSport2LevelContent[index].title}
                                </div>
                                <LevelIndicator
                                    difficultyLevel={index}
                                    isCurrentDifficultyLevel={isSelectedDifficulty(
                                        index + 1,
                                    )}
                                />
                            </div>
                            <div
                                class={cn(
                                    'text-justify',
                                    isSelectedDifficulty(index + 1) &&
                                        'text-content-brand',
                                )}
                            >
                                {tourSport2LevelContent[index].descr}
                            </div>
                        </div>
                    {/each}
                {/if}
            </div>
        </div>
    </div>
</Drawer>
