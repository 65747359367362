<script lang="ts">
    import { createCalendar, createPagination } from '@melt-ui/svelte';
    import dayjs from 'dayjs';
    import { parseDate } from '@internationalized/date';
    import {
        VpChevronLeftIcon,
        VpChevronRightIcon,
    } from '@decathlon-experience/icons/svelte';

    import MinPricePerMonthsList from '$saga/views/Tour/VisibleDates/MinPricePerMonthsList.svelte';
    import { findFirstAvailableMonth } from '$saga/utils/date';
    import {
        MinPriceDays,
        MinPriceMonth,
    } from '$saga/views/Tour/VisibleDates/types';
    import {
        getCurrentDayPriceAmount,
        getQuantileSizeClass,
        hasQuantile,
    } from '$saga/views/Tour/VisibleDates/utils';
    import cn from '$common/utils/cn';
    import { dateValue } from '$saga/store/tourFitDate';
    import CalendarLegend from '$saga/views/Tour/VisibleDates/Fit/CalendarLegend.svelte';

    export let minPricePerMonths: MinPriceMonth[],
        closedSales,
        minPricePerDays: MinPriceDays,
        isBookable: boolean;
    let currentActiveMonth = dayjs();

    const {
        elements: {
            root,
            prevButton: paginationPrev,
            nextButton: paginationNext,
        },
        states: { range, totalPages, page },
    } = createPagination({
        count: minPricePerMonths.length - 1,
        perPage: 9,
    });

    const {
        elements: { calendar, cell, grid, heading, nextButton, prevButton },
        states: { months, headingValue, weekdays },
        helpers: { isDateDisabled, isDateUnavailable, setYear, setMonth },
    } = createCalendar({
        locale: 'fr',
        numberOfMonths: 2,
        minValue: parseDate(dayjs().add(1, 'day').format('YYYY-MM-DD')),
        maxValue: parseDate(
            dayjs().add(minPricePerMonths.length, 'month').format('YYYY-MM-DD'),
        ),
        isDateDisabled: (date) => {
            let isDisabled = false;
            for (const closedDate of closedSales) {
                const d = dayjs(date.toString());
                // '[]' is inclusive start+end day dates
                if (
                    d.isBetween(
                        closedDate.startDate,
                        closedDate.endDate,
                        'day',
                        '[]',
                    )
                )
                    isDisabled = true;
            }

            return isDisabled;
        },
        value: dateValue,
    });

    // place the first month correctly according to available dates
    currentActiveMonth = findFirstAvailableMonth(closedSales);
    setYear(currentActiveMonth.year());
    setMonth(currentActiveMonth.month() + 1);

    //  when we select a new month in the list
    $: {
        if (currentActiveMonth) {
            setMonth(currentActiveMonth.month() + 1);
            setYear(currentActiveMonth.year());
        }
    }
</script>

<MinPricePerMonthsList
    {root}
    prevButton={paginationPrev}
    nextButton={paginationNext}
    {page}
    {range}
    bind:currentActiveMonth
    {totalPages}
    {minPricePerMonths}
/>

<!-- Special calendar with custom day content (45px size) -->
<div {...$calendar} use:calendar class="m-auto px-2xl py-m">
    <div class="flex items-center justify-center pb-xs">
        <button type="button" {...$prevButton} use:prevButton>
            <VpChevronLeftIcon class="size-xl" />
        </button>
        <div
            {...$heading}
            use:heading
            class="flex w-full justify-between gap-x-8 text-sm capitalize text-content-neutral"
        >
            <div class="flex-1 text-center">
                {$headingValue.split('-')[0]}
            </div>
            <div class="flex-1 text-center">
                {$headingValue.split('-')[1]}
            </div>
        </div>
        <button type="button" {...$nextButton} use:nextButton>
            <VpChevronRightIcon class="size-xl" />
        </button>
    </div>
    <div class={cn('flex items-center justify-center gap-x-8')}>
        {#each $months as month}
            <table {...$grid} use:grid>
                <thead aria-hidden="true">
                    <tr class="">
                        {#each $weekdays as day}
                            <th
                                class="text-center text-xs font-semibold leading-4 text-content-quiet"
                            >
                                {day}
                            </th>
                        {/each}
                    </tr>
                </thead>
                <tbody>
                    {#each month.weeks as days}
                        <tr>
                            {#each days as date}
                                <td
                                    class={cn('text-center text-xs leading-4')}
                                    role="gridcell"
                                    aria-disabled={$isDateDisabled(date) ||
                                        $isDateUnavailable(date)}
                                >
                                    <div
                                        {...$cell(date, month.value)}
                                        use:cell
                                        class={cn(
                                            'flex flex-col items-center justify-center border-b-[3px] border-solid',
                                            getQuantileSizeClass(
                                                minPricePerDays[
                                                    dayjs(
                                                        date.toString(),
                                                    ).format('YYYY-MM-DD')
                                                ]?.quantile,
                                            ),
                                        )}
                                    >
                                        <div>
                                            {date.day}
                                        </div>
                                        <div class="whitespace-nowrap">
                                            {getCurrentDayPriceAmount(
                                                minPricePerDays,
                                                date,
                                            )}
                                        </div>
                                    </div>
                                </td>
                            {/each}
                        </tr>
                    {/each}
                </tbody>
            </table>
        {/each}
    </div>
</div>
{#if hasQuantile(minPricePerMonths)}
    <CalendarLegend />
{/if}
{#if !isBookable}
    <div class="w-full text-right text-xs italic">
        les prix indiqués sont "à partir de"
    </div>
{/if}

<style lang="postcss">
    [data-melt-popover-content] {
        @apply z-50 border border-solid border-bord-quiet bg-white;
    }
    [data-melt-calendar] {
        @apply h-fit w-fit overflow-y-scroll;
        scrollbar-width: none;
    }

    table {
        @apply w-fit border-spacing-3xs;
        border-collapse: separate;
    }

    [data-melt-calendar-cell] {
        @apply m-auto size-3xl bg-container-quiet desktop:size-[45px];

        &:hover {
            @apply bg-container-commercial-neutral;
        }
    }

    [data-melt-calendar-cell][data-disabled] {
        @apply pointer-events-none cursor-default border-white bg-white text-content-quiet;
    }

    [data-melt-calendar-cell][data-unavailable] {
        @apply pointer-events-none cursor-default text-content-quiet;
    }

    [data-melt-calendar-cell][data-selected] {
        @apply border-border-brand bg-blue-brand text-white;
    }

    [data-melt-calendar-cell][data-selected][data-disabled] {
        @apply pointer-events-none cursor-default border-white bg-white text-content-quiet;
    }

    [data-melt-calendar-cell][data-outside-visible-months] {
        @apply pointer-events-none cursor-default text-content-quiet;
    }

    [data-melt-calendar-cell][data-outside-month] {
        @apply pointer-events-none opacity-0;
    }
    [data-melt-calendar-nextButton][data-disabled] {
        @apply opacity-[0.38];
    }
    [data-melt-calendar-prevButton][data-disabled] {
        @apply opacity-[0.38];
    }
</style>
