<script lang="ts">
    import Drawer from '$saga/components/Drawer.svelte';

    export let security, otherInfo, dict;
</script>

<Drawer titleLabel={dict.header}>
    <div slot="triggerlink">
        <div class="cursor-pointer underline hover:no-underline">
            {dict.label}
        </div>
    </div>

    <div class="flex flex-col gap-m px-m py-s desktop:px-3xl desktop:py-m">
        {#if security}
            <div
                class="border-b border-solid border-border-quiet pb-xs text-[20px] font-semibold"
            >
                {dict.securityTitle}
            </div>
            <p>
                <!-- eslint-disable-next-line svelte/no-at-html-tags -->
                {@html security}
            </p>
        {/if}

        {#if otherInfo}
            <div
                class="border-b border-solid border-border-quiet pb-xs text-[20px] font-semibold"
            >
                {dict.otherTitle}
            </div>
            <!-- eslint-disable-next-line svelte/no-at-html-tags -->
            <p>{@html otherInfo}</p>
        {/if}
    </div>
</Drawer>
