import { Controller } from '@hotwired/stimulus';

/* stimulusFetch: 'lazy' */
export default class extends Controller {
    static targets = ['container'];

    static values = {
        url: String,
    };

    connect() {
        this.fetchInsurance(this.urlValue);
    }

    async fetchInsurance(url) {
        const response = await fetch(url, {
            method: 'POST',
            Headers: {
                Accept: 'application.json',
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok || 204 === response.status) {
            this.containerTarget.parentElement.remove();
            return;
        }

        this.containerTarget.innerHTML = await response.text();
    }
}
