<script lang="ts">
    import Features from '$saga/views/Tour/features/Features.svelte';
    import { TourSport } from '$saga/views/Tour/features/types';

    export let physicalDifficulty: number,
        titleLabel: string,
        physicalDifficultyLabel: string,
        tourSport1: TourSport | null,
        tourSport2: TourSport | null,
        tourSport1Svg: string,
        tourSport2Svg: string;

    // TODO add remaining features
    // tourSports,
    // tourFirstSport,
    //
    // idealPeriods,
    // formattedIdealPeriods,
    //
    // environments,
    //
    // minAgeAllowed,
    // maxAgeAllowed,
    //
    // carbonImpact;
</script>

<Features
    {physicalDifficulty}
    {physicalDifficultyLabel}
    {titleLabel}
    {tourSport1}
    {tourSport2}
    {tourSport1Svg}
    {tourSport2Svg}
/>
