import { Controller } from '@hotwired/stimulus';
import flatpickr from 'flatpickr';
import { French } from 'flatpickr/dist/l10n/fr';

/* stimulusFetch: 'lazy' */
export default class extends Controller {
    connect() {
        // if dialog container => special positioning
        const dialogContainer = this.element.closest('dialog');
        flatpickr(this.element, {
            dateFormat: 'd/m/Y',
            minDate: new Date(),
            locale: French,
            disableMobile: true,
            ...(dialogContainer
                ? { appendTo: this.element.parentElement }
                : {}),
            onOpen: (selectedDates, dateStr, instance) => {
                if (dialogContainer) {
                    const calendarElement = instance.calendarContainer;
                    calendarElement.classList.add(
                        '!bottom-[52px]',
                        '!left-0',
                        '!top-auto',
                    );
                }
            },
        });
    }
}
