import { Controller } from '@hotwired/stimulus';
import debounce from 'lodash/debounce';

/* stimulusFetch: 'lazy' */
export default class AutoSubmitFormController extends Controller {
    static targets = ['input', 'loader'];

    initialize() {
        this.submit = debounce(this.submit, 300).bind(this);
    }

    connect() {
        this.inputTargets.forEach((input) => {
            input.addEventListener('input', this.submit);
        });
    }

    disconnect() {
        this.inputTargets.forEach((input) => {
            input.removeEventListener('input', this.submit);
        });
    }

    submit() {
        if (this.hasLoaderTarget) {
            this.loaderTargets.forEach(function (item) {
                item.classList.remove('hidden');
            });
        }

        this.element.requestSubmit();
    }
}
